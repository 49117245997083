/**
 * @deprecated make API calls via server actions using functions defined in `src/data/`
 */

import { useQueryClient } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import { z } from "zod";
import { sessionKey, sessionQuery, sessionStaleTime } from "~/auth.client";

type CreateApiRequestOptions<ResponseSchema extends z.ZodTypeAny> = {
  baseUrl: string;
  path: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  token: string;
  body?: unknown;
  response: ResponseSchema;
};

export async function createApiRequest<ResponseSchema extends z.ZodTypeAny>(
  options: CreateApiRequestOptions<ResponseSchema>,
): Promise<z.infer<ResponseSchema>> {
  const url = `${options.baseUrl}${options.path}`;
  const method = options.method;
  let headers: HeadersInit = { Authorization: `Bearer ${options.token}` };
  let body: FormData | string | undefined;

  if ("body" in options) {
    if (options.body instanceof FormData) {
      body = options.body;
    } else {
      headers = { ...headers, "Content-Type": "application/json" };
      body = JSON.stringify(options.body);
    }
  }

  const response = await fetch(url, { method, headers, body });

  if (!response.ok) {
    // FIXME improve the error being produced here
    const error = await response.text();
    throw new Error(error || response.statusText);
  }

  // assuming API only ever returns `application/json`
  const result = await response.json();
  const parseResult = options.response.safeParse(result);

  if (!parseResult.success) {
    // Quick fix for logging errors. React query suppresses logging errors in
    // production
    console.error(parseResult.error);
    throw parseResult.error;
  }

  return parseResult.data;
}

// Necessary to remove build time environment variables from the app
const intellproApiBaseUrlContext = createContext(
  "https://sockets-api.dev.env.intellpro.com",
);

export const IntellproApiBaseUrlProvider = intellproApiBaseUrlContext.Provider;
export const useIntellproApiBaseUrl = () =>
  useContext(intellproApiBaseUrlContext);

type UseApiRequestOptions<ResponseSchema extends z.ZodTypeAny> =
  | Omit<CreateApiRequestOptions<ResponseSchema>, "baseUrl" | "token">
  | (() => Omit<CreateApiRequestOptions<ResponseSchema>, "baseUrl" | "token">);

export function useGetApiToken(): () => Promise<string> {
  // We want a callback that returns a promise here hence why we use the
  // query client directly rather than a useQuery hook
  const queryClient = useQueryClient();

  return async () => {
    const session = await queryClient.fetchQuery({
      queryKey: sessionKey,
      queryFn: sessionQuery,
      staleTime: sessionStaleTime,
    });

    return session.apiToken;
  };
}

export function useApiRequest<ResponseSchema extends z.ZodTypeAny>(
  options: UseApiRequestOptions<ResponseSchema>,
): () => Promise<z.infer<ResponseSchema>> {
  const baseUrl = useIntellproApiBaseUrl();
  const getToken = useGetApiToken();
  return async () => {
    const opts = typeof options === "function" ? options() : options;
    return await createApiRequest({
      ...opts,
      baseUrl,
      token: await getToken(),
    });
  };
}
