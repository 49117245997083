"use client";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { config } from "@fortawesome/fontawesome-svg-core";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LDProvider, basicLogger } from "launchdarkly-react-client-sdk";
import { useLayoutEffect, useState } from "react";
import { IntellproApiBaseUrlProvider } from "~/api2/lib";
import { getQueryClient } from "~/query-client";

// See https://docs.fontawesome.com/web/use-with/react/use-with
//   Note those docs say to do it in `src/app/layout.tsx` but that doesn't work
//   if that is a server component, it will still add a <style>
config.autoAddCss = false;

type DatadogUserInfo = {
  id: string;
  name: string;
};

export type DatadogInit = {
  applicationIdForWeb: string;
  applicationIdForOffice: string;
  clientToken: string;
  enabled: boolean;
  env: string;
  version: string;
  user: DatadogUserInfo | null;
};

function initializeDatadog({
  applicationIdForOffice,
  applicationIdForWeb,
  enabled,
  ...config
}: DatadogInit) {
  const commonConfig = {
    ...config,
    service: "workspace-nextjs",
    site: "datadoghq.com",
    sessionSampleRate: 100,
  };

  datadogLogs.init({ ...commonConfig, forwardErrorsToLogs: true });

  if (enabled) {
    const isOutlookAddin =
      global.location?.hostname.startsWith("outlook") ||
      global.location?.pathname.startsWith("/office");

    datadogRum.init({
      ...commonConfig,
      applicationId: isOutlookAddin
        ? applicationIdForOffice
        : applicationIdForWeb,
      allowedTracingUrls: [
        "https://staging-api.intellpro.io",
        "https://api.intellpro.io",
      ],
      defaultPrivacyLevel: "allow",
      sessionReplaySampleRate: 100,
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
    });
  } else {
    datadogLogs.logger.setHandler("console");
  }
}

let browserDevDatadogInitialized = false;

function useDatadogInit(init: DatadogInit) {
  // Don't need the return value here, just initialize
  useState(() => {
    // Don't initialize datadog on the server
    if (typeof window !== "undefined") {
      // This prevents datadog from getting initialized multiple times when
      // making changes during development. For every other environment, we
      // want to avoid making side effects. The build process will compile
      // this branch out completely.
      if (process.env.NODE_ENV === "development") {
        if (browserDevDatadogInitialized) return;
        browserDevDatadogInitialized = true;
      }
      initializeDatadog(init);
    }
  });

  // We use a layout effect so that datadog gets a user set asap
  useLayoutEffect(() => {
    if (init.enabled && init.user) {
      datadogLogs.setUser(init.user);
      datadogRum.setUser(init.user);

      return () => {
        datadogLogs.clearUser();
        datadogRum.clearUser();
      };
    }
  }, [init]);
}

export type ClientSetupProps = {
  datadog: DatadogInit;
  /** TODO remove this once src/api2 has been moved away from entirely */
  intellproApiBaseUrl: string;
  launchdarklyClientId: string;
  children: React.ReactNode;
};

/**
 * Renders React context providers and initializes datadog for the entire app.
 *
 * Rather than set build time environment via `NEXT_PUBLIC_`, this component
 * gets passed those values from the server layout component which then gets
 * serialized and hydrated on the client. This means be careful what values are
 * passed-in as they are visible to the client, so no secrets for example.
 */
export function ClientSetup({
  datadog,
  intellproApiBaseUrl,
  launchdarklyClientId,
  children,
}: ClientSetupProps) {
  useDatadogInit(datadog);
  const queryClient = getQueryClient();

  return (
    <IntellproApiBaseUrlProvider value={intellproApiBaseUrl}>
      <LDProvider
        clientSideID={launchdarklyClientId}
        context={{ key: "anonymous", kind: "user" }}
        options={{ logger: basicLogger({ level: "warn" }) }}
      >
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          {children}
        </QueryClientProvider>
      </LDProvider>
    </IntellproApiBaseUrlProvider>
  );
}
